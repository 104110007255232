import React from 'react'
import { Link } from 'gatsby'
import Slider from 'react-slick'
// import Img from '../utils/image2'
import Img from 'gatsby-image'
import './slick.css'
import './thumbnail.css'
import config from '../../config'
import { useState, useImperativeHandle, useEffect } from 'react'

function Thumbnail(props, ref) {
  const [listView, setListView] = useState(false)
  const images = props.images
  const length = images.edges.length
  const maxN = Math.floor(props.xWidth / config.thumb.width)
  const _children = getChildren(props)
  const _s = props.settings || {}
  const settings = {
    dots: false,
    arrow: false,
    infinite: true,
    // autoplay: true,
    slidesToShow: maxN,
    slidesToScroll: 1,
    swipeToSlide: false,
    draggable: false,
    centerMode: true,
    lazyLoad: true,
    focusOnSelect: false,
    // variableWidth: true,
    accessibility: true,
    // centerPadding: '10px',
    ..._s,
    ...config.slider,
    asNavFor: props.slider
  }

  return (
    <>
      <div
        className="thumbWrapper"
        onMouseLeave={() => {
          props.setAutoplay(true)
        }}
        onMouseEnter={() => {
          props.setAutoplay(false)
        }}
        // ref={(ref) => (this.div = ref)}
        // css={{ height: this.state.listView ? 0 : config.thumb.height }}
      >
        <Slider {...settings} ref={ref}>
          {_children}
        </Slider>
        {length / maxN > 1.1 && (
          <div
            css={{
              position: 'absolute',
              right: 10,
              top: -50,
              height: 50,
              width: 50,
              // backgroundColor: 'red',
              fontSize: 50,
              fontStyle: 'bold',
              cursor: 'pointer',
              display: 'block'
            }}
            onMouseEnter={() => setListView(true)}
            onClick={() => setListView(true)}
          >
            <ListIcon />
          </div>
        )}

        {listView && (
          <ListView
            {...{ ...props, _children, maxN, length, listView, setListView }}
          />
        )}
      </div>
      <div
        css={{
          position: 'fixed',
          top: 0,
          bottom: config.thumb.height * 2,
          left: 0,
          right: 0,
          display: listView ? 'block' : 'none',
          background: 'transparent',
          zIndex: -1
        }}
        onClick={() => setListView(false)}

        // onMouseEnter={() => props.clock.stop()}
      />
    </>
  )
}

function ListIcon({ length, maxN }) {
  // if (length / maxN < 1.1) return <></>
  return (
    <div
      style={{
        color: 'lightgrey',
        animation: 'pulse 1s ease-in 2s 2 alternate',
        overflow: 'visible',
        height: 50,
        width: 50
        // backgroundImage: 'url("/assets/view_module.svg")',
        // backgroundColor: config.thumb.background
      }}
    >
      <img src="/assets/view_module.svg"></img>
    </div>
  )
}

function getChildren(props) {
  return props.images.edges.map((edge, index) => {
    const image = edge.node.resolutions
    const name = image.originalName
    const [video, hasVideo] = name.split('.ref.')
    const has360 = /p2v$|360$|vr$/.test(video)
    return (
      <div
        className="ImgWrapper"
        css={{
          width: config.thumb.width,
          height: config.thumb.height,
          display: 'inline-block',
          padding: '6px 3px',
          position: 'relative',
          cursor: 'pointer'
        }}
        key={index}
        onClick={() => {
          props.thumb.slickGoTo(index)
          // this.props.onChange(index)
        }}
      >
        {hasVideo && <div className= {has360? 'vrLogo': 'videoLogo'} />}
        <Img
          resolutions={{ ...image, width: '100%', height: '100%' }}
          className="imgOuterWrapper fill"
		  imgStyle={{objectFit : "contain"}}
          // className={this.state.current == index ? 'thumb active' : 'thumb'}
          // loading='eager'
        />
      </div>
    )
  })
}

function ListView({
  _children,
  maxN,
  length,
  listView,
  setListView,
  thumb,
  xHeight
}) {
  // useEffect(
  //   function () {
  //     if (thumb?.slickPause && listView) {
  //       thumb.slickPause()
  //     }
  //     return () => {
  //       thumb.slickPlay()
  //       console.log(888)
  //     }
  //   }
  //   // [listView]
  // )
  let mHeight = Math.ceil(length / maxN) * config.thumb.height
  // mHeight = config.thumb.height*3
  return (
    <div
      className="thumbListWrapper"
      style={{
        width: '100%',
        textAlign: 'center',
        height: listView ? mHeight : 0,
        bottom: '0',
        position: 'absolute',
        background: 'rgba(27, 27, 27, 0.9)',
        maxHeight: xHeight * 0.4,
        overflowY: 'scroll',
        transition: 'all 0.5s ease'
        // display: this.state.listView ? 'block' : 'none',
      }}
      onMouseLeave={() => {
        // props.clock.reset()
        console.log('leaving...')
        setListView(false)
      }}
    >
      {/* <div className='thumbList'
        style={{
          width: 'auto',
          margin: 'auto',
          display: 'inline-block'
        }}> */}
      {/* {_children} */}
      {listView ? _children : null}
      {/* </div> */}
    </div>
  )
}

export default React.forwardRef(Thumbnail)
