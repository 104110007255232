import React from 'react'
import { Link } from 'gatsby'
import Slider from 'react-slick'
// import Img from '../utils/image2'
import Img from 'gatsby-image'
import './slick.css'
import config from '../../config'
import MediaModal from './MediaModal'
import NP from 'nprogress'
import '../utils/nprogress.css'
export default class MySlider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cache: {},
      current: 0
    }
    this.next = this.next.bind(this)
  }
  componentDidMount() {
    this.loading(0)
  }

  loading(index) {
    if (!this.state.cache[index])
      this.loadingDelay = setTimeout(() => {
        NP.start()
        NP.set(0.4)
      }, 1000)
  }
  imgLoaded(index) {
    if (this.loadingDelay) {
      clearTimeout(this.loadingDelay)
      this.loadingDelay = null
    }
    if (NP.isStarted()) {
      NP.done()
      NP.remove()
    }
    this.state.cache[index] = 1
  }

  next() {
    if (
      this.state.cache[this.state.current + 1] &&
      this.state.cache[this.state.current]
    ) {
      this.slick.slickNext()
    }
  }

  render() {
    // console.log(this.state,'slick rendering...')
    let _s = {}
    if (this.props.settings) {
      _s = this.props.settings
    }
    let settings = {
      beforeChange: (old, index) => {
        if (this.props.onChange) {
          this.props.onChange(index)
        }
      },
      afterChange: (index) => {
        // this.loading(index)
        // console.log('slick changed...')
        this.state.current = index
      },
      autoplay: true,
      pauseOnHover: false,
      dots: true,
      infinite: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: true,
      draggable: false,
      swipeToSlide: false,
      ...config.slider,
      ..._s,
      asNavFor: this.props.thumb
    }
    // settings.autoplay && this.props.clock.auto()
    settings.autoplay = false

    let center = {
      margin: 'auto',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)'
    }

    const arrows = (
      <div className="layer fill" css={{ position: 'absolute' }}>
        <div
          className="effect-lines fill"
          css={{
            position: 'absolute',
            '@media screen and (max-width: 799px)': {
              display: 'none'
            }
          }}
        >
          <div className="effect-lines-right" />
          <div className="effect-lines-left" />
        </div>
        <div
          className="slider-arrows fill"
          css={{ fontSize: config.arrow.height }}
        >
          <div className="arrow-wrapper" css={{ left: 0 }}>
            <a
              id="arrow-left-project"
              className="arrow-project y-center"
              onClick={() => {
                this.slick.slickPrev()
              }}
              onMouseLeave={() => {
                this.props.setAutoplay(true)
              }}
              onMouseEnter={() => {
                this.props.setAutoplay(false)
              }}
            >
              ❮
            </a>
          </div>
          <div className="arrow-wrapper" css={{ right: 0 }}>
            <a
              id="arrow-right-project"
              className="arrow-project y-center"
              onClick={() => {
                this.slick.slickNext()
              }}
              onMouseLeave={() => {
                this.props.setAutoplay(true)
              }}
              onMouseEnter={() => {
                this.props.setAutoplay(false)
              }}
            >
              ❯
            </a>
          </div>
        </div>
      </div>
    )
    return (
      <div
        className="slick"
        css={{
          position: 'relative',
          background: config.slider.background
        }}
        ref={(ref) => (this.div = ref)}
      >
        {arrows}
        <MediaModal ref={(ref) => (this.modal = ref)} />
        <Slider {...settings} ref={(slick) => (this.slick = slick)}>
          {this.props.images.edges.map((edge, index) => {
            let edges0 = this.props.images.edges
            let length0 = edges0.length
            let i2 = index + 1
            if (i2 == length0) i2 = 0
            let nextImage = this.props.images.edges[i2].node.sizes
            let image = edge.node.sizes
            let name = image.originalName
            let [refPath, hasRef] = name.split('.ref.')
            return (
              <div
                key={index}
                className="ImgWrapper"
                css={{ position: 'relative' }}
              >
                {hasRef && (
                  <div
                    className="play-button"
                    onClick={() => {
                      this.props.setAutoplay(false)
                      //   this.setState({
                      //     video: true,
                      //     image: image,
                      //     refPath
                      //   })
					//   console.log(this.props.xRatio, image.aspectRatio,this.div.clientWidth, image, this.props)
                      this.modal.open(
                        {
                          height: this.div.clientHeight,
                          width: this.div.clientWidth,
                          current: index,
                          video: true,
                          image: image,
                          refPath,
						  rWidth: this.props.xRatio < image.aspectRatio? this.div.clientWidth : image.aspectRatio * this.props.xHeight
                        },
                        () => this.props.setAutoplay(true)
                      )
                    }}
                  />
                )}
                <div
                  className="imgCenter"
                  css={
                    // this.props.xRatio<1.25?
                    // {height: this.props.xWidth/image.aspectRatio, width: '100%',...center}
                    // :
                    { height: '100%', width: '100%', ...center }
                  }
                >
                  <Img
                    sizes={{
                      ...image,
                      sizes: '(max-width: 500px) 120vw, 100vw'
                    }}
                    className="imgOuterWrapper fill"
                    imgStyle={{ objectFit: 'contain' }}
                    onLoad={() => this.imgLoaded(index)}
                  />
                  <Img
                    sizes={{
                      ...nextImage,
                      sizes: '(max-width: 500px) 120vw, 100vw'
                    }}
                    onLoad={() => {
                      this.state.cache[index + 1] = 1
                    }}
                    loading="eager"
                    css={{ display: 'none' }}
                  />
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    )
  }
}
// export default React.forwardRef((props, ref) => {
//   return <MySlider {...props} forwardedRef={ref} />;
// })
