import { useState, useEffect } from 'react'

function getUpdateDimensions() {
  var w = window,
    d = document,
    documentElement = d.documentElement,
    body = d.getElementsByTagName('body')[0],
    width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
    height = w.innerHeight || documentElement.clientHeight || body.clientHeight
  return { xWidth: width, xHeight: height, xRatio: width / height }
}

export function useScreenSize() {
  if (typeof window == 'undefined') return [{}]
  const [screenSize, setScreenSize] = useState(getUpdateDimensions())
  const reSize = () => {
    setScreenSize(getUpdateDimensions())
  }
  useEffect(() => {
    window.addEventListener('resize', reSize)
    return () => window.removeEventListener('resize', reSize)
  })
  return [screenSize]
}
// const clock = {
//   auto: () => {
//     // console.log('auto...')
//     this.timer && clearInterval(this.timer)
//     this.timer = setInterval(() => {
//       this.slideNext()
//     }, config.slider.autoplaySpeed)
//   },
//   reset: () => {
//     // console.log('reset...')
//     clearInterval(this.timer)
//     this.clock.auto()
//   },
//   stop: () => {
//     // console.log('try to stop..')
//     clearInterval(this.timer)
//     this.timer = null
//   }
// }


