import React, { useState, useCallback, useEffect } from 'react'
import MySlider from '../components/slider'
import Thumbnail from '../components/thumbnail'
import config from '../../config'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { useScreenSize } from '../components/hooks'

export default function Gallery(props) {
  // const [state, setState] = useSate({ current: 0 })
  const [screenSize] = useScreenSize()
  const [slider, setSlider] = useState()
  const [thumb, setThumb] = useState({ slickNext: () => {} })
  const sliderRef = useCallback((current) => setSlider(current), [])
  const thumbRef = useCallback((current) => setThumb(current), [])
  const [isAutoplay, setAutoplay] = useState(true)
  // useAuto(()=>{console.log(Math.random(),44);thumbRef.current?.slickNext()}, config.slider.autoplaySpeed, isAutoplay)
  const states = { slider: slider?.slick, thumb, setAutoplay, isAutoplay }
  useEffect(() => {
    // console.log('timer', isAutoplay)
    if (!isAutoplay) return
    const interval = setInterval(() => {
      slider.next()
    }, config.slider.autoplaySpeed)
    return () => {
      clearInterval(interval)
    }
  }, [isAutoplay, slider])

  const settings = {
    dots: false,
    infinite: true
  }
  return (
    <Layout>
      <div
        className="mainScreen"
        style={{
          bottom: 0,
          top: 0,
          height: '100%',
          animation: 'slideInRight 0.3s ease-in 0s 1 alternate'
        }}
      >
        <div
          className="mySlider fill"
          style={{
            position: 'relative'
          }}
        >
          <MySlider
            images={props.data.images}
            settings={settings}
            // ref={(ref) => (this.slider = ref)}
            {...screenSize}
            ref={sliderRef}
            {...states}
          />
        </div>

        <div
          className="thumbBar"
          css={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            overflow: 'visible',
            zIndex: '1000',
            height: config.thumb.height,
            // backgroundColor: '#1a1a1a',
            // opacity: 0.5,
            // background: 'rgba(0, 0, 0, 0.5)',
            background: config.thumb.background,
            transition: 'opacity 1s ease',
            opacity: 0.75,
            '&:hover': {
              opacity: '1'
            },
            '@media screen and (max-height: 500px)': {
              // height: 0,
              opacity: '0.5',
              background: 'rgba(27, 27, 27, 0.5)'
            }
          }}
        >
          <Thumbnail
            images={props.data.thumbImages}
            ref={thumbRef}
            {...screenSize}
            {...states}
          />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query GalleryIndex($slug: String = "/home") {
    images: allImageSharp(
      filter: { fields: { slug: { eq: $slug } } }
      sort: { order: ASC, fields: sizes___originalName }
    ) {
      edges {
        node {
          id
          sizes(quality: 75, maxWidth: 1000) {
            base64
            src
            srcSet
            aspectRatio
            originalName
            sizes
          }
        }
      }
    }
    thumbImages: allImageSharp(
      filter: { fields: { slug: { eq: $slug } } }
      sort: { order: ASC, fields: resolutions___originalName }
    ) {
      edges {
        node {
          id
          resolutions(width: 100) {
            base64
            src
            srcSet
            aspectRatio
            originalName
            width
            height
          }
        }
      }
    }
  }
`
