import React, { useEffect } from 'react'
import NP from 'nprogress'
import '../utils/nprogress.css'
// import { P2V } from './p2v'
export default class MediaModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = { modal: { width: 0, height: 0, opacity: 0 }, refPath: null }
  }
  open(v, callBack) {
    let path = v.refPath.replace(/^.+__\.?/, '')
    let pa = path.split('.')
    let pf = pa.pop()
    let p1 = pa.join('.')
    let ps = {
      mp4: { path: `/video/${path}`, type: 'video' },
      p2v: { path: `/p2v/${p1}/`, type: 'embed' },
      cp: { path: `/cp/#${p1}...${v.rWidth}`, type: 'embed' },
      youtube: {
        path: `https://www.youtube.com/embed/${p1}?autoplay=1&rel=0`,
        type: 'embed',
        closeButton: { top: '80px', right: '40px' }
      }
    }
    let alias = { '360': 'p2v', vr: 'p2v', yt: 'youtube' }
    ps = ps[pf] || ps[alias[pf]] || ps['p2v']
    this.setState({
      closeButton: {},
      ...v,
      ...ps,
      callBack,
      modal: { width: '100%', height: '100%', opacity: 0.75 }
    })
    NP.start()
    window.NP = NP
  }
  close() {
    this.setState({
      type: null,
      // video: false,
      modal: { width: 0, height: 0, opacity: 0 }
    })
    this.state.callBack()
    NP.done()
    NP.remove()
  }
  render() {
    return (
      <div
        ref={(ref) => (this.modal = ref)}
        css={{
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: this.state.modal.width,
          height: this.state.modal.height,
          margin: 'auto',
          backgroundColor: 'black',
          borderRadius: '2px',
          zIndex: 3000,
          transition: 'all 0.2s ease'
          // boxShadow: '0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.28)'
        }}
      >
        <a
          css={{
            cursor: 'pointer',
            position: 'absolute',
            fontSize: '40px',
            color: 'white',
            opacity: this.state.modal.opacity,
            right: '40px',
            top: '40px',
            zIndex: '3100',
            '&:hover': {
              color: 'orange',
              fontSize: '45px'
            },
            transition: 'opacity 5s ease',
            ...this.state.closeButton
          }}
          onClick={() => this.close()}
        >
          X
        </a>
        <div
          className="videoWrapper"
          css={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
            // display: 'flex',
          }}
        >
          {this.state.type == 'video' && (
            <video
              controls
              autoPlay
              loop
              playsInline
              // width= {this.state.width}
              // height= {this.state.height}
              ref={(ref) => (this.video = ref)}
              css={{
                width: '100%',
                height: '100%'
              }}
              onCanPlay={() => NP.done()}
            >
              <source src={this.state.path} />
            </video>
          )}
          {this.state.type == 'embed' && (
            <iframe
              src={this.state.path}
              width="100%"
              height="100%"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              onLoad={() => NP.done()}
            />
          )}
        </div>
      </div>
    )
  }
}
